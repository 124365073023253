.shipping-handling-wrapper {
  margin-bottom: 1.5rem;
  .btn-shipping-handling {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
}

.shipping-and-handling-modal-wrapper {
  .shippingAndHandling-body {
    padding-top: 0;

    .shippingAndHandling__rate-table-tab {
      .shippingAndHandling__rate-table {
        width: 100%;
      }

      .shippingAndHandling__rate-table tr {
        border-bottom-color: color(neutral-200);
        border-bottom-style: solid;
        border-bottom-width: 1px;

        td:nth-child(2) {
          font-weight: 700;
          padding-left: 10px;
          text-align: right;
          width: auto;
        }

        td {
          padding: 20px 0;
        }

        .shippingAndHandling__rate-table-giftCardInfo {
          font-size: rem(11);
        }
      }

      .shippingAndHandling__rate-table-tab-helpText {
        padding-bottom: 0;
        padding-top: 30px;

        .shippingAndHandling__rate-table-tab-helpText-header {
          padding-bottom: 0;
          padding-top: 10px;
        }

        p {
          margin-bottom: 0;
        }
      }

      .shippingAndHandling__additional-info-tab-header,
      .shippingAndHandling__additional-info-tab-content-header {
        margin-bottom: 20px;
      }

      .shippingAndHandling__additional-info-tab-content p {
        margin-bottom: 20px;
      }
    }

    .shippingAndHandling__additional-info-tab {
      margin-top: 25px;
      padding-top: 25px;

      .shippingAndHandling__additional-info-tab-header {
        margin-bottom: 20px;
      }

      .shippingAndHandling__additional-info-tab-content p {
        margin-bottom: 20px;
      }
    }

    .shippingAndHandling__rate-table-header,
    .shippingAndHandling__additional-info-tab-header,
    .shippingAndHandling__additional-info-tab-content-header,
    .shippingAndHandling__rate-table-tab-helpText-header {
      color: inherit;
    }
  }

  .shippingAndHandling-CA {
    padding-bottom: 20px;

    .shippingAndHandling__rate-table-tab {
      padding-top: 20px;

      .shippingAndHandling__rate-table {
        width: 100%;
      }

      .shippingAndHandling__rate-table tr {
        border-bottom-color: color(neutral-200);
        border-bottom-style: solid;
        border-bottom-width: 1px;

        td:nth-child(3) {
          font-weight: 700;
          padding-left: 10px;
          text-align: right;
          width: auto;
        }

        td {
          padding: 20px 0;
        }

        .shippingAndHandling__rate-table-giftCardInfo {
          font-size: rem(11);
        }
      }

      .shippingAndHandling__rate-table-tab-helpText {
        padding-bottom: 0;
        padding-top: 30px;

        .shippingAndHandling__rate-table-tab-helpText-header {
          padding-bottom: 0;
          padding-top: 10px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    @include media-query(700) {
      padding-bottom: 20px;
    }
  }

  .shippingAndHandling-footer {
    font-size: inherit;
    margin: 20px 0 0;
    padding: 20px;

    ol {
      list-style-type: upper-roman;

      p {
        margin-bottom: 20px;
      }
    }

    .module-section__footer-content+.noShippingUSContent {
      padding-top: 20px;
    }

    .noShippingUSContent {
      padding-bottom: 20px;
    }
  }
}